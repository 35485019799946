<template>
  <div class="table" :class="size" v-if="data">
    <table>
      <thead>
        <tr>
          <th
            :class="item.status"
            v-for="(item, index) in data.thead"
            :key="index"
          >
            <div>
              <router-link :to="item.link_to" v-if="item.link_to">{{
                item.value
              }}</router-link>
              <span v-else>{{ item.value }}</span>
              <span class="icon" v-if="item.icon" title="lesson done">
                <vs-icon :icon="item.icon" />
              </span>
              <span
                class="leftIcon"
                v-if="item.leftIcon"
                title="lesson have test"
              >
                <vs-icon icon="colorize" />
              </span>
              <span
                class="top-right-icon"
                v-if="item.topRightIcon"
                title="Online lesson"
              >
                <vs-icon icon="online_prediction" />
              </span>
              <span
                class="top-left-icon"
                v-if="item.topLeftIcon"
                title="Books give out"
              >
                <vs-icon :icon="item.topLeftIcon" />
              </span>
              <sub v-if="item.subValue" class="sub">{{ item.subValue }}</sub>
              <div
                class="comment"
                v-if="item.comment && !isLessonTable"
                :title="item.comment"
              ></div>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="row in data.rows" :key="row.rowId">
          <td
            v-for="(item, index) in row.columns"
            :key="index"
            :class="
              `${item.status ? item.status : ''} ${item.edited ? 'edit' : ''} ${
                item.sub && item.sub >= 85 ? 'high' : ''
              } ${
                item.sub && item.sub >= 75 && item.sub < 85 ? 'subhigh' : ''
              } ${
                item.sub && item.sub >= 50 && item.sub < 75 ? 'middle' : ''
              } ${item.sub && item.sub < 50 ? 'low' : ''} col-${
                item.columnType
              } ${item.is_trial ? 'is-trial' : ''}`
            "
            class="table-cell"
          >
            <div
              @mouseenter="withPopup ? popupShowHandler($event, item) : null"
              @mouseleave="withPopup ? popupHideHandler() : null"
            >
              <router-link :to="item.link_to" v-if="item.link_to">
                <span v-html="`${item.value}`"></span>
                <sub
                  v-if="item.sub"
                  v-html="item.sub + (item.unit || '')"
                  class="sub"
                ></sub>
                <span
                  v-if="item.columnType == 'test'"
                  class="test-comments-count"
                >
                  <vs-icon
                    icon="comment"
                    size="small"
                    v-if="item.popup.comments"
                  ></vs-icon>
                  {{ item.popup.comments ? item.popup.comments.length : '' }}
                </span>
              </router-link>
              <div v-else-if="item.columnType == 'bookOut' && item.value">
                <vs-icon icon="done" color="success"></vs-icon>
                {{ $moment(item.value * 1000).format('DD.MM.YYYY') }}
                <div>
                  <router-link
                    :to="{
                      name: 'Lessons',
                      params: { lesson_id: item.bookOutLesson.id },
                    }"
                    v-if="item.bookOutLesson.id"
                  >
                    <span v-html="`${item.bookOutLesson.name}`"></span>
                  </router-link>
                </div>
                <vs-icon
                  icon="close"
                  color="dark"
                  class="delete-boot-out"
                  @click="$emit('cancelGiveOut', item.rowId)"
                  title="Cancel give out"
                ></vs-icon>
              </div>

              <span v-else-if="item.edited" class="edit-field">
                <textarea
                  type="text"
                  icon="add"
                  :value="item.value"
                  :data-index="`${item.columnType}-${index}`"
                  @keydown="nameKeydown($event, item.columnType, index)"
                  @focus="
                    cellFocus(
                      $event,
                      item.columnType,
                      item.columnId,
                      item.itemId,
                      item.rowId
                    )
                  "
                  @keyup="
                    changeCell(
                      $event,
                      item.columnType,
                      item.columnId,
                      item.itemId,
                      item.rowId
                    )
                  "
                ></textarea>
              </span>
              <span v-else>
                <span v-html="`${item.value}`"></span>
                <sub
                  v-if="item.sub"
                  v-html="item.sub + (item.unit || '')"
                  class="sub"
                ></sub>
              </span>
              <div class="attendance-sub">
                <div
                  class="comment"
                  v-if="item.comment && !isLessonTable"
                  :title="item.comment"
                ></div>
                <div
                  class="surprises"
                  v-if="
                    item.surprises && item.surprises.length && !isLessonTable
                  "
                  :title="item.surprises.map(s => s.name).join(', ')"
                >
                  <span
                    class="surprises__count"
                    v-if="item.surprises.length > 1"
                    >{{ item.surprises.length }}</span
                  >
                </div>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="save-tooltip" :class="`save-tooltip-${tablekey}`">
      <div class="save-tooltip__content">
        <vs-button
          data-type="cell-action-button"
          type="flat"
          icon="check"
          title="Save"
          @click="saveHandle"
        ></vs-button>
        <vs-button
          data-type="cell-action-button"
          type="flat"
          color="dark"
          icon="close"
          title="Cancel"
          @click="cancelEditHandle"
        ></vs-button>
      </div>
    </div>
  </div>
</template>

<script>
import eventBus from '@/eventBus.js'

export default {
  name: 'CustomTable',
  props: [
    'data',
    'pages',
    'isLessonTable',
    'isStudentTable',
    'size',
    'tablekey',
    'withPopup',
  ],
  data() {
    return {
      withMonthPages: true,
      pageIndex: 0,
      editedCell: {},
      selectedCellElement: null,
      sourceValue: '',
    }
  },
  methods: {
    changeCell: function(e, columnType, columnId, itemId, rowId) {
      this.editedCell = {
        link_id: columnId,
        id: itemId,
        student_id: rowId,
      }
      this.editedCell[columnType] = e.target.value
    },
    cellFocus: function(e, columnType, columnId, itemId, rowId) {
      const tooltip = document.querySelector('.save-tooltip-' + this.tablekey)
      tooltip.style.display = 'block'
      e.target.parentElement.appendChild(tooltip)
      this.selectedCellElement = e.target
      // предзаполнение объекта
      this.sourceValue = e.target.value

      this.editedCell = {
        link_id: columnId,
        id: itemId,
        student_id: rowId,
      }
      this.editedCell[columnType] = e.target.value
    },
    lostFocus: function(e) {
      console.log(e)
      // if (
      //   !e.relatedTarget ||
      //   e.relatedTarget.dataset.type != "cell-action-button"
      // ) {
      //   e.target.classList.add("changed");
      //   this.tooltipHideAction();
      // }
    },
    nameKeydown(e, columnType, index) {
      if (columnType == 'exist') {
        if (e.key == 'Backspace') return
        if (!/^[-|1|н|б|ч|0]$/.test(e.key)) {
          e.preventDefault()
        }
        if (e.key == 'Enter') {
          this.saveHandle().then(() => {
            e.target.classList.add('saved')

            const nextField = document.querySelector(
              `textarea[data-index="exist-${index + 1}"]`
            )
            if (nextField) {
              nextField.focus()
            }
          })
          return
        }
      }
      this.selectedCellElement.classList.add('changed')
    },
    saveHandle: function() {
      return new Promise((resolve, reject) => {
        const data = { ...this.editedCell }
        if (this.isStudentTable) {
          data.update_by_student = true
          if (data?.exist == '-') {
            data.exist = 'н'
          }
        }
        this.$store
          .dispatch('lessons/visitLessonAction', {
            data,
          })
          .then(() => {
            this.editedCell = {}
            this.selectedCellElement.classList.remove('changed')
            this.tooltipHideAction()

            this.$vs.notify({
              title: 'Lesson saved',
              text: `Changes in lesson saved`,
              color: 'success',
              position: 'top-right',
            })
            resolve()
          })
          .catch(message => {
            this.$vs.notify({
              title: 'Lesson error',
              text: `${message}`,
              color: 'danger',
              position: 'top-right',
              time: 6000,
            })
            reject()
          })
      })
    },
    cancelEditHandle: function() {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: `Confirm`,
        text: 'Are You sore to cancel changes?',

        accept: this.acceptCancelEdit,
      })
    },
    acceptCancelEdit: function() {
      this.editedCell = {}
      this.selectedCellElement.value = this.sourceValue
      this.selectedCellElement.classList.remove('changed')
      this.selectedCellElement = null
      this.sourceValue = ''

      this.tooltipHideAction()
    },
    tooltipHideAction: function() {
      const tooltip = document.querySelector('.save-tooltip-' + this.tablekey)
      tooltip.style.display = 'none'
    },
    popupShowHandler: function(e, data) {
      if (!data.popup) return
      let place = {
        left: e.x,
        top: e.y,
      }
      eventBus.$emit('showTestResult', { ...data, ...place })
    },
    popupHideHandler: function() {
      eventBus.$emit('hideTestResult')
    },
  },
}
</script>

<style lang="scss">
.table {
  overflow-x: auto;
  margin-bottom: 15px;
  &.tests {
    th,
    td {
      &:not(:nth-child(1)) {
        font-size: 0.8em !important;
        max-width: 70px;
        box-sizing: border-box;
      }
    }
  }
  table {
    border-collapse: collapse;

    td,
    th {
      border: 1px solid #777;
      padding: 7px 15px;
      text-align: center;
      &.visits-cell {
        text-align: center;
      }
      &.item-name {
        width: 250px;
      }
      &.editable {
        cursor: pointer;
        &:hover {
          background-color: #eee;
        }
      }
      &:nth-child(1) {
        text-align: left;
      }
      &.edit {
        padding: 0;
      }
      &.is-trial {
        box-shadow: inset 0px 0px 0px 2px #5b3cc4;
        position: relative;
        &::after {
          content: 'trial';
          font-size: 0.7rem;
          position: absolute;
          top: 1px;
          right: 3px;
          color: #5b3cc4;
        }
      }
      .icon {
        bottom: -10px;
        right: -15px;
      }
      .leftIcon {
        bottom: -9px;
        left: -15px;
        opacity: 0.7;
      }
      .top-left-icon {
        top: -11px;
        left: -14px;
        opacity: 0.7;
      }
      .top-right-icon {
        top: -11px;
        right: -14px;
        opacity: 0.7;
      }
      sub {
        display: block;
        font-weight: normal;
      }
      .icon,
      .leftIcon,
      .top-left-icon,
      .top-right-icon {
        position: absolute;
        font-size: 14px;
        i {
          font-size: 1em;
          width: 14px;
        }
      }
      & > div {
        position: relative;
      }
    }
    .cancelled {
      background-color: #ffc8f0;
    }
    .wrong {
      background-color: #fff3c8;
    }
    .empty {
      background-color: #f8e291;
    }
    .danger {
      background-color: #ffc8c8;
    }
    .disabled {
      background-color: #e6e6e6;
    }
    .warn {
      background-color: #f2c8ff;
    }
    .attendance-sub {
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
    }
    .comment {
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background-color: #c00;
      cursor: pointer;
    }
    .surprises {
      margin-left: 3px;
      &::before {
        content: '';
        display: block;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background-color: var(--font-primary-color);
        cursor: pointer;
      }
      span {
        font-size: 0.6em;
        padding-left: 1px;
      }
    }
  }
  .visits__pages {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    & > * {
      margin: 5px;
    }
    .page-name {
      min-width: 100px;
      text-align: center;
    }
  }
  &.small {
    table {
      td,
      th {
        padding: 2px 7px;
        &.item-name {
          width: 100px;
        }
      }
    }
  }
  .edit-field {
    width: 100%;
    height: 100%;
    margin: 0;
    display: inline-block;
    position: relative;
    input,
    textarea {
      border: none;
      background: none;
      width: auto;
      padding: 10px;
      width: 100%;
      &.changed {
        background-color: #efefef;
        outline: 2px solid #c00;
      }
    }
  }
  .col-exist {
    max-width: 10px;
    text-align: center;
    textarea {
      resize: none;
    }
  }
  .col-comment {
    min-width: 350px;
    white-space: pre-line;
  }
  .col-count {
    font-weight: bold;
  }
}
.save-tooltip {
  position: absolute;
  left: 100%;
  top: -2px;
  background-color: #eee;
  border-radius: 0 5px 5px 0;
  z-index: 200;
  display: none;
  .save-tooltip__content {
    display: flex;
    flex-flow: row nowrap;
    button {
      float: none !important;
      &.vs-button {
        padding: 0;
      }
    }
  }
}
.delete-boot-out {
  position: absolute;
  top: -0.5rem;
  right: -1rem;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 50%;
  &:hover {
    box-shadow: 0 0 7px 1px #ccc;
  }
}
.test-comments-count {
  position: absolute;
  bottom: -0.5rem;
  right: -0.8rem;
  font-size: 0.5rem;
  width: 1rem;
  .vs-icon {
    width: 0.5rem;
    height: 0.5rem;
    font-size: 0.5rem;
  }
}
</style>
